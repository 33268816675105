import styled from 'styled-components'

const Image = styled.img`
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

export default Image
